<template>
  <v-container data-app>
    <v-form
        ref="form"
        v-if="cityId">
      <v-row>
        <v-col class="mobile-col-basis">
          <v-card>
            <v-card-title>Основное</v-card-title>
            <v-card-text>
              <v-row class="row-d-block">
                <v-subheader>Код</v-subheader>
                <v-text-field
                    v-model="city.code"
                    dense
                    disabled
                    outlined/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Название города</v-subheader>
                <v-text-field
                    v-model="city.city"
                    dense
                    name="city"
                    outlined
                    placeholder="Город"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>В городе</v-subheader>
                <v-text-field
                    v-model="city.city_of"
                    dense
                    name="city_of"
                    outlined
                    placeholder="Например, «Москве»"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Принадлежит Москве</v-subheader>
                <v-checkbox
                    v-model="city.owner_is_msk"
                    dense
                    true-value="1"
                    false-value="0"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Адрес</v-subheader>
                <v-text-field
                    v-model="city.address"
                    dense
                    name="address"
                    outlined
                    placeholder="Адрес"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Фонд зп клинеров</v-subheader>
                <v-text-field
                    v-model="city.payment_fond"
                    placeholder="50"
                    outlined/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Процент клинеров по умолчанию</v-subheader>
                <v-text-field
                    v-model="city.payment_percent"
                    placeholder="40"
                    outlined
                />
              </v-row>
              <v-row>
                <v-subheader>Авторасчет процента клинеров</v-subheader>
                <v-checkbox
                    v-model="city.cleaners_auto_percent"
                    dense
                    true-value="1"
                    false-value="0"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Часы работы</v-subheader>
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="city.worktime_from"
                        outlined>
                      <template v-slot:prepend>С</template>
                    </v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-model="city.worktime_to"
                        outlined>
                      <template v-slot:prepend>По</template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Дата начала работы (если не указано, то с даты первого заказа)</v-subheader>
                <Date v-model="city.date_start"/>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="mobile-col-basis">
          <v-card>
            <v-card-title>Контакты</v-card-title>
            <v-card-text>
              <v-row class="row-d-block">
                <v-subheader>Основной телефон</v-subheader>
                <v-text-field
                    v-model="city.phone"
                    dense
                    name="phone"
                    outlined
                    type="phone"
                    placeholder="Телефон"/>
              </v-row>
              <v-row class="row-d-block py-2">
                <v-subheader style="height: 100%" class="px-2">
                  Доп. номера телефонов
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          class="ml-2"
                          size="18"
                          v-bind="attrs"
                          v-on="on"
                      >mdi-information-outline
                      </v-icon>
                    </template>
                    <span>Отображаются на сайте</span>
                  </v-tooltip>
                </v-subheader>
                <v-text-field
                    v-for="(phone, index) in add_phones" :key="index"
                    dense
                    outlined
                    v-model="add_phones[index]"
                    append-outer-icon="mdi-close"
                    @click:append-outer="add_phones.splice(index, 1)"/>
                <v-btn @click="add_phones.push('')">Добавить</v-btn>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader style="height: 100%" class="px-2">Телефон для клинеров
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          class="ml-2"
                          size="18"
                          v-bind="attrs"
                          v-on="on"
                      >mdi-information-outline
                      </v-icon>
                    </template>
                    <span>Отображается клинерам для связи с офисом</span>
                  </v-tooltip>
                </v-subheader>
                <v-text-field
                    v-model="city.phone_bot"
                    dense
                    name="phone_bot"
                    outlined
                    type="phone"
                    placeholder="Телефон для клинеров"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Email</v-subheader>
                <v-text-field
                    v-model="city.email"
                    dense
                    name="email"
                    outlined
                    placeholder="Email"/>
              </v-row>
              <v-row class="row-d-block py-2">
                <v-subheader style="height: 100%" class="px-2">
                  Доп. номера для телефонии
                  <InfoTip value="Указанные выше номера вводить не надо"/>
                </v-subheader>
                <v-text-field
                    v-for="(phone, index) in telephony_phones" :key="index"
                    dense
                    v-mask="$store.state.setMask(telephony_phones[index], true)"
                    outlined
                    v-model="telephony_phones[index]"
                    append-outer-icon="mdi-close"
                    @click:append-outer="telephony_phones.splice(index, 1)"/>
                <v-btn @click="telephony_phones.push('')">Добавить</v-btn>
              </v-row>
              <v-row class="row-d-block py-2">
                <v-subheader style="height: 100%" class="px-2">Номера для найма
                  <InfoTip value="Номера, которые используются для найма клинеров"/>
                </v-subheader>
                <v-text-field
                    v-for="(phone, index) in phones_hr" :key="index"
                    dense
                    v-mask="$store.state.setMask(phones_hr[index], true)"
                    outlined
                    v-model="phones_hr[index]"
                    append-outer-icon="mdi-close"
                    @click:append-outer="phones_hr.splice(index, 1)"/>
                <v-btn @click="phones_hr.push('')">Добавить</v-btn>
              </v-row>
              <!--              <v-row class="row-d-block">-->
              <!--                <v-subheader>ISO код (Для коллтрекинга)</v-subheader>-->
              <!--                <v-text-field-->
              <!--                    v-model="city.iso_code"-->
              <!--                    dense-->
              <!--                    outlined-->
              <!--                    placeholder="Например, KDA"/>-->
              <!--              </v-row>-->
              <v-row>
                <v-subheader>Настроено в манго в аккаунте Москвы</v-subheader>
                <v-checkbox
                    v-model="city.mango_is_moscow_account"
                    dense
                    true-value="1"
                    false-value="0"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Номер переадресации манго (если равен номеру города, то оставьте пустым)</v-subheader>
                <v-text-field
                    v-model="city.mango_group_id"
                    dense
                    outlined
                    placeholder="Например, 26473"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>ID виджета коллтрекинга манго</v-subheader>
                <v-text-field
                    v-model="city.mango_widget_id"
                    dense
                    outlined
                    placeholder="Например, 26473"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Ключ Salebot</v-subheader>
                <v-text-field
                    v-model="city.salebot_key"
                    dense
                    outlined
                    placeholder=""/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Население (тыс чел)</v-subheader>
                <v-text-field
                    v-model="city.population"
                    dense
                    outlined
                    placeholder="Например, 500"/>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mobile-col-basis">
          <v-card>
            <v-card-title>Мессенджеры/Соц.сети</v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-row class="row-d-block">
                    <v-subheader>VK</v-subheader>
                    <v-text-field
                        v-model="city.vk"
                        dense
                        name="vk"
                        placeholder="VK"
                        outlined/>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row class="row-d-block">
                    <v-subheader>Facebook</v-subheader>
                    <v-text-field
                        v-model="city.fb"
                        dense
                        name="fb"
                        placeholder="Facebook"
                        outlined/>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-row class="row-d-block">
                    <v-subheader>Instagram</v-subheader>
                    <v-text-field
                        v-model="city.instagram"
                        dense
                        name="instagram"
                        placeholder="Instagram"
                        outlined/>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row class="row-d-block">
                    <v-subheader>Telegram</v-subheader>
                    <v-text-field
                        v-model="city.telegram"
                        dense
                        name="telegram"
                        placeholder="Telegram"
                        outlined/>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-subheader>WhatsApp</v-subheader>
                  <v-text-field
                      v-model="city.whatsapp"
                      dense
                      placeholder="79998887766"
                      outlined/>
                </v-col>
                <v-col>
                  <v-subheader>Viber</v-subheader>
                  <v-text-field
                      v-model="city.viber"
                      dense
                      placeholder="79998887766"
                      outlined/>
                </v-col>
                <v-col>
                  <v-subheader>Twitter</v-subheader>
                  <v-text-field
                      v-model="city.twitter"
                      dense
                      placeholder="Twitter"
                      outlined/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-subheader style="flex-direction: column;height: auto;align-items: flex-start;">Ссылка на яндекс
                    отзывы
                  </v-subheader>
                  <v-text-field
                      v-model="city.yandex_reviews_link"
                      dense
                      placeholder="Ссылка"
                      outlined/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-subheader style="flex-direction: column;height: auto;align-items: flex-start;">Ссылка на Google
                    отзывы
                  </v-subheader>
                  <v-text-field
                      v-model="city.google_reviews_link"
                      dense
                      placeholder="Ссылка"
                      outlined/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-subheader style="flex-direction: column;height: auto;align-items: flex-start;">Ссылка на Vk
                    отзывы
                  </v-subheader>
                  <v-text-field
                      v-model="city.vk_reviews_link"
                      dense
                      placeholder="Ссылка"
                      outlined/>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col class="mobile-col-basis">
          <v-card>
            <v-card-title>Payture</v-card-title>
            <v-card-text>
              <v-row v-for="name in Object.keys(paytures).filter(_name=>!['payture_email', 'payture_btc'].includes(_name))"
                     :key="name"
                     class="row-d-block">
                <v-subheader>{{paytureTitles[name] }}
                </v-subheader>
                <v-checkbox v-if="name === 'payture_enabled' || name === 'payture_sendSMS'"
                            v-model="city[name]"
                            true-value="1"
                            false-value="0"
                            label=""></v-checkbox>
                <v-text-field v-if="name !== 'payture_enabled' && name !== 'payture_sendSMS' && name !=='payture_tax'"
                              v-model="city[name]"
                              outlined></v-text-field>
                <v-select v-if="name === 'payture_tax'"
                          v-model="city[name]"
                          outlined
                          :items="taxes"
                          item-value="value"
                          item-text="text"></v-select>
              </v-row>
              <v-row>
                <v-subheader>Терминал БТС</v-subheader>
                <v-checkbox
                    v-model="city.payture_btc"
                    true-value="1"
                    false-value="0"
                    label=""></v-checkbox>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mobile-col-basis">
          <v-card>
            <v-card-title>Остальное</v-card-title>
            <v-card-text>
              <v-row class="row-d-block">
                <v-subheader>Почты для уведомлений</v-subheader>
                <v-text-field
                    v-for="(email, index) in notification_emails" :key="index"
                    dense
                    outlined
                    v-model="notification_emails[index]"
                    append-outer-icon="mdi-close"
                    @click:append-outer="notification_emails.splice(index, 1)"/>
                <v-btn @click="notification_emails.push('')">Добавить</v-btn>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Часовой пояс</v-subheader>
                <v-select
                    v-model="city.timezone_code"
                    :items="timezones"
                    item-text="label"
                    item-value="value"
                    dense
                    name="timezone_code"
                    outlined
                    placeholder="Часовой пояс"/>
              </v-row>
              <v-row>
                <v-subheader>Открыт</v-subheader>
                <v-checkbox
                    v-model="city.published"
                    dense
                    name="published"
                    label=""
                    true-value="1"
                    false-value="0"/>
              </v-row>
              <v-row>
                <v-subheader>Открывать на поддомене (для SEO)</v-subheader>
                <v-checkbox
                    v-model="city.open_in_subdomain"
                    dense
                    true-value="1"
                    false-value="0"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Google Ads ID</v-subheader>
                <v-text-field outlined v-model="city.google_ads_id" placeholder="123-456-7890"></v-text-field>
              </v-row>
            </v-card-text>
            <v-card-title>Блок качества</v-card-title>
            <v-card-text>

              <v-row class="row-d-block">
                <v-subheader>Отображать блок</v-subheader>
                <v-checkbox
                    v-model="city.supervisor_enabled"
                    true-value="1"
                    false-value="0"
                    label=""></v-checkbox>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>ФИО</v-subheader>
                <v-text-field
                    v-model="city.supervisor_name"
                    dense
                    outlined
                    placeholder="ФИО"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Телефон</v-subheader>
                <v-text-field
                    v-model="city.supervisor_phone"
                    dense
                    v-mask="$store.state.setMask(city.supervisor_phone)"
                    outlined
                    type="phone"
                    placeholder="Телефон"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Почта</v-subheader>
                <v-text-field
                    v-model="city.supervisor_email"
                    dense
                    outlined
                    placeholder="Почта"/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Телеграм</v-subheader>
                <v-text-field
                    v-model="city.supervisor_telegram"
                    dense
                    outlined
                    placeholder="Телеграм"/>
              </v-row>
            </v-card-text>
            <v-card-title>Юр. лицо (для футера на сайте)</v-card-title>
            <v-card-text>
              <v-row class="row-d-block">
                <v-subheader>ИНН</v-subheader>
                <v-text-field
                    v-model="city.company_inn"
                    dense
                    type="number"
                    outlined
                >
                  <template v-slot:append>
                    <v-btn :loading="btnLoading.inn" @click="findByINN" small>Найти</v-btn>
                  </template>
                </v-text-field>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Название</v-subheader>
                <v-text-field
                    v-model="city.company_name"
                    dense
                    outlined
                />
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>ОГРН</v-subheader>
                <v-text-field
                    v-model="city.company_ogrn"
                    dense
                    type="number"
                    outlined
                />
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Адрес</v-subheader>
                <v-text-field
                    v-model="city.company_address"
                    dense
                    outlined
                />
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>БИК</v-subheader>
                <v-text-field
                    v-model="city.company_bik"
                    dense
                    outlined
                >
                  <template v-slot:append>
                    <v-btn :loading="btnLoading.bik" @click="findByBIK" small>Найти</v-btn>
                  </template>
                </v-text-field>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Банк</v-subheader>
                <v-text-field
                    v-model="city.company_bank"
                    dense
                    outlined
                />
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>К/С</v-subheader>
                <v-text-field
                    v-model="city.company_ks"
                    dense
                    outlined
                />
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Р/С</v-subheader>
                <v-text-field
                    v-model="city.company_rs"
                    dense
                    outlined
                />
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="mobile-col-basis">
          <v-card>
            <v-card-title>Метрики</v-card-title>
            <v-card-text>
              <v-row class="row-d-block">
                <v-subheader>Коды метрик</v-subheader>
                <v-textarea v-model="city.metrics" rows="10" outlined/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>HTML в HEAD</v-subheader>
                <v-textarea v-model="city.html_head" rows="10" outlined/>
              </v-row>
              <v-row class="row-d-block">
                <v-subheader>Роистат код проекта</v-subheader>
                <v-text-field
                    v-model="city.roistat_project"
                    dense
                    outlined/>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mobile-col-basis">
          <v-card>
            <v-card-title style="justify-content: space-between;">
              <span>Прайс-лист</span>
              <v-btn @click="copyFromMoscow" small>Сделать как в Москве</v-btn>
            </v-card-title>
            <v-card-text>
              <v-row class="flex-md-nowrap">
                <v-col class="col-12 col-md-2 py-0 py-md-4">
                  <v-subheader class="align-end"><strong>Скидки на регулярные уборки (%)</strong></v-subheader>
                </v-col>
                <div class="d-flex justify-space-between flex-nowrap">
                  <div class="py-0 py-md-2 px-2" v-for="(rooms_count) in [2,3,4, 5]" :key="rooms_count">
                    <v-subheader>
                      {{ ['Раз в месяц', 'Раз в 2 недели', 'Раз в неделю', '2 раза в неделю'][rooms_count - 2] }}
                    </v-subheader>
                    <v-text-field
                        v-model="price['regular'+rooms_count]"
                        outlined
                        :class="price['regular'+rooms_count]!==city.price['regular'+rooms_count] ? 'updated' : ''"
                    >
                    </v-text-field>
                  </div>
                </div>
              </v-row>
              <template v-for="(type) in ['standart', 'general', 'remont']">
                <v-row class="flex-md-nowrap pt-2" :key="type+'_price'">
                  <v-col class="col-12 col-md-2 py-0 pt-md-4 d-flex align-center">
                    <v-subheader class="align-end"><strong>{{ $store.state.cleanTypes[type] }}</strong></v-subheader>
                  </v-col>
                  <div class="d-flex justify-space-between flex-nowrap">
                    <div class="py-0 py-md-2 px-2" v-for="(rooms_count) in [1,2,3,4,5]" :key="rooms_count">
                      <v-subheader :style="$vuetify.breakpoint.width < 600 ? 'height: 18px' : ''">{{ rooms_count }} комн
                      </v-subheader>
                      <v-text-field
                          v-model="price[type+rooms_count]"
                          outlined
                          :class="price[type+rooms_count]!==city.price[type+rooms_count] ? 'updated' : ''"
                      >
                      </v-text-field>
                    </div>
                  </div>
                </v-row>
                <v-row class="flex-md-nowrap pt-2" :key="type+'_duration'">
                  <v-col class="col-12 col-md-2 py-0 pt-md-4 d-flex align-center">
                    <v-subheader class="align-end"><strong>Время (мин)</strong></v-subheader>
                  </v-col>
                  <div class="d-flex justify-space-between flex-nowrap">
                    <div class="py-0 py-md-2 px-2" v-for="(rooms_count) in [1,2,3,4,5]" :key="rooms_count">
                      <v-subheader :style="$vuetify.breakpoint.width < 600 ? 'height: 18px' : ''">{{
                          Math.round(price[type + rooms_count] / city.serviceDuration[type + '_' + rooms_count] * 60)
                        }} {{ $store.state.user.city.country.currency }}/ч
                      </v-subheader>
                      <v-text-field
                          :disabled="$store.state.user.n_id!=1"
                          v-model="city.serviceDuration[type+'_'+rooms_count]"
                          outlined
                      >
                      </v-text-field>
                    </div>
                  </div>
                </v-row>
              </template>
              <v-row v-for="(service, name) in price_services" :key="name">
                <v-col>
                  <v-subheader>{{ service }}</v-subheader>
                  <v-text-field
                      v-model="price[name]"
                      outlined
                      :class="price[name]!==city.price[name] ? 'updated' : ''"
                  />
                </v-col>
                <v-col>
                  <template
                      v-if="!['eco', 'delivery', 'parogen', 'welcome', 'fast','getkey', 'window_min', 'housemap_bonus', 'him_smell', 'him_koja', 'him_jivotnie', 'client_promo1', 'client_promo2', 'client_promo3', 'cleaner_revenue1', 'cleaner_revenue2', 'cleaner_penalty_small', 'cleaner_penalty_big', 'cleaner_min', 'cleaner_max', 'chim_min', 'bonus_small', 'bonus_medium'].includes(name)">
                    <v-subheader>Время
                      ({{
                        Math.round(price[name] / (city.serviceDuration[name + 'Num'] || city.serviceDuration[name]) * 60)
                      }}
                      {{ $store.state.user.city.country.currency }}/ч)
                    </v-subheader>
                    <v-text-field
                        :disabled="$store.state.user.n_id!=1"
                        :value="city.serviceDuration[name+'Num'] ||city.serviceDuration[name]"
                        @change="val=>city.serviceDuration[name+'Num'] ?$set(city.serviceDuration, name+'Num', val):$set(city.serviceDuration,name,val)"
                        outlined
                    />
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col class="mobile-col-basis">
          <v-card>
            <v-card-title>Регионы</v-card-title>
            <v-card-actions>
              <v-btn @click="addRegion">Добавить</v-btn>
            </v-card-actions>
            <v-card-text>
              <v-data-table
                  :items="regions"
                  :headers="regionHeaders"
                  item-key="n_id"
                  no-data-text="Регионов нет"
                  loading-text="Загрузка..."
                  :loading="loading_regions"
                  :footer-props="{'items-per-page-options':[-1]}"
                  mobile-breakpoint="0"
              ></v-data-table>
            </v-card-text>
            <v-card-title>Тексты</v-card-title>
            <v-card-text>
              <v-data-table
                  :items="city.articles"
                  :headers="[{text: 'Название', value:'title'}]"
                  item-key="n_id"
                  @click:row="$refs.article.open($event, cityId)"
                  :footer-props="{'items-per-page-options':[-1]}"
                  mobile-breakpoint="0"
              ></v-data-table>
            </v-card-text>
            <template v-if="city.id==1">
              <v-card-title>Услуги</v-card-title>
              <v-card-text>
                <v-data-table
                    :items="city.services"
                    :headers="[{text: '№', value:'n_id'},{text: 'Название', value:'title'}]"
                    item-key="n_id"
                    @click:row="$refs.service.open($event, cityId)"
                    :footer-props="{'items-per-page-options':[-1]}"
                    mobile-breakpoint="0"
                ></v-data-table>
              </v-card-text>
            </template>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="position: sticky;bottom: -10px">
        <v-col>
          <v-card style="height: auto;margin: 0;box-shadow: 0 0 10px #aaa;">
            <v-card-title>
              <v-btn :loading="loading" @click="updateCity">Сохранить</v-btn>
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
    <AddRegion ref="addRegionModal"/>
    <Article ref="article"/>
    <Service ref="service"/>
  </v-container>
</template>

<script>

import {Server} from "@/api/server";
import AddRegion from "@/views/countries/AddRegion";
import Article from "./Article";
import Service from "./Service";
import Date from "../../components/Date";
import InfoTip from "../../components/InfoTip.vue";

const server = new Server();
export default {
  name: "EditCity",
  components: {
    InfoTip,
    Date,
    Service,
    Article,
    AddRegion,
  },
  data() {
    return {
      loading: false,
      cities: null,
      cityId: this.$route.params.city_id,
      country_id: this.$route.params.country,
      city: {
        notification_emails: [],
        add_phones: [],
        telephony_phones: [],
        phones_hr: [],
        price: {},
        articles: [],
        serviceDuration: {}
      },
      notification_emails: [],
      add_phones: [],
      telephony_phones: [],
      phones_hr: [],
      paytures: {},
      paytureTitles: {
        'payture_enabled': 'Включить кассу',
        'payture_add': 'Терминал Add',
        'payture_pay': 'Терминал Pay',
        'payture_inn': 'ИНН',
        'payture_check_pass': 'Пароль для чеков',
        'payture_tax': 'Налогообложение',
        'payture_email': 'Email для чеков',
        'payture_sendSMS': 'Отправлять чеки по SMS'
      },
      taxes: [
        {text: 'Общая, ОСН', value: '0'},
        {text: 'Упрощенная доход, УСН доход', value: '1'},
        {text: 'Упрощенная доход минус расход, УСН', value: '2'},
        {text: 'Единый налог на вмененный доход, ЕНВД', value: '3'},
        {text: 'Единый сельскохозяйственный налог, ЕСН', value: '4'},
        {text: 'Патентная система налогообложения', value: '5'},
      ],
      timezones: [
        {label: '-12 GMT', value: '-15'},
        {label: '-11 GMT', value: '-14'},
        {label: '-10 GMT', value: '-13'},
        {label: '-9 GMT', value: '-12'},
        {label: '-8 GMT', value: '-11'},
        {label: '-7 GMT', value: '-10'},
        {label: '-6 GMT', value: '-9'},
        {label: '-5 GMT', value: '-8'},
        {label: '-4 GMT', value: '-7'},
        {label: '-3 GMT', value: '-6'},
        {label: '-2 GMT', value: '-5'},
        {label: '-1 GMT', value: '-4'},
        {label: '0 GMT', value: '-3'},
        {label: '+1 GMT', value: '-2'},
        {label: '+2 GMT', value: '-1'},
        {label: '+3 GMT (Москва)', value: '0'},
        {label: '+4 GMT', value: '1'},
        {label: '+5 GMT', value: '2'},
        {label: '+6 GMT', value: '3'},
        {label: '+7 GMT', value: '4'},
        {label: '+8 GMT', value: '5'},
        {label: '+9 GMT', value: '6'},
        {label: '+10 GMT', value: '7'},
        {label: '+11 GMT', value: '8'},
        {label: '+12 GMT', value: '9'},
      ],
      regular_labels: {
        regular2: 'Поддерживающая уборка от 2х раз в месяц (Скидка %)',
        regular3: 'Поддерживающая уборка от 3х раз в месяц (Скидка %)',
        regular4: 'Поддерживающая уборка от 4х раз в месяц (Скидка %)',
        regular5: 'Поддерживающая уборка от 5х раз в месяц (Скидка %)',
      },
      price_services: {
        wc: 'Дополнительный туалет',
        bal: 'Дополнительный балкон',
        window: 'Окно',
        windowRemont: 'Окно после ремонта',
        lustra: 'Люстра',
        holod: 'Холодильник',
        duhov: 'Духовка',
        hood: 'Вытяжка',
        glad1: 'Глажки',
        glad2: 'Поменять бельё',
        glad3: 'Особые поручения ',
        getkey: 'Заехать за ключами',
        fast: 'Срочная',
        eco: 'Эко уборка (%)',
        delivery: 'Доставка оборудования',
        parogen: 'Парогенератор',
        welcome: 'Welcome bonus',
        himchistka_divana: 'Химчистка доп места дивана (>2)',
        himchistka_divana_fix: 'Химчистка дивана фиксированная за 2 места',
        himchistka_kresla: 'Химчистка кресла',
        himchistka_stuliev: 'Химчистка стула',
        himchistka_kovrov: 'Химчистка ковра за кв.м.',
        setki: 'Макситная сетка',
        jaluzi: 'Жалюзи',
        svch: 'СВЧ',
        him_koja: 'Наценка за химчистку с кожей в процентах',
        him_jivotnie: 'Наценка за химчистку от животных в процентах',
        chim_min: 'Минимальный выезд химчистка',
        window_min: 'Минимальный выезд окна',
      },
      btnLoading: {
        bik: false,
        inn: false,
      },
      price: {},
      regions: [],
      regionHeaders: [
        {text: 'Регион', value: 'title'},
        {text: 'Код', value: 'code'},
      ],
      loading_regions: true,
    }
  },
  methods: {
    getCity() {
      server.request(`cities/get/${this.cityId}`, {}, (data) => {
        let paytureKeys = Object.keys(data.response).filter(el => el.includes('payture'));
        paytureKeys.forEach(el => {
          this.paytures[el] = data.response[el]
        })
        this.display(data.response);
      })
    },
    copyFromMoscow() {
      this.$store.state.server.request('cities/getMoscowPrice', {}, data => {
        this.price = data.response;
        this.$forceUpdate();
      });
    },
    findByINN() {
      this.btnLoading.inn = true;
      this.$store.state.server.request('cities/findByINN', {inn: this.city.company_inn}, data => {
        this.btnLoading.inn = false;
        if (!data.response || !data.response.data || !data.response.data.name) return this.$root.notify('Юр. лицо не найдено', 'error')
        this.city.company_name = data.response.data.name.short_with_opf;
        this.city.company_ogrn = data.response.data.ogrn;
        this.city.company_address = data.response.data.address.value;
        this.$forceUpdate();
      }, () => this.btnLoading.inn = false);
    },
    findByBIK() {
      this.btnLoading.bik = true;
      this.$store.state.server.request('cities/findByBIK', {bik: this.city.company_bik}, data => {
        this.btnLoading.bik = false
        if (!data.response || !data.response.company_bank) return this.$root.notify('Информация по БИК не найдена', 'error')
        for (let i in data.response) this.city[i] = data.response[i];
        this.$forceUpdate();
      }, () => this.btnLoading.bik = false);
    },
    display(city) {
      if (!city.add_phones) this.add_phones = [];
      else this.add_phones = city.add_phones.split(',');
      if (!city.telephony_phones) this.telephony_phones = [];
      else this.telephony_phones = city.telephony_phones.split(',');
      if (!city.phones_hr) this.phones_hr = [];
      else this.phones_hr = city.phones_hr.split(',');
      this.notification_emails = JSON.parse(city.notification_emails);
      if (!this.notification_emails) this.notification_emails = [];
      this.city = city;
      this.price_services = city.priceEditCRM;
      this.price = JSON.parse(JSON.stringify(this.city.price))
    },
    updateCity() {
      this.loading = true;
      this.city.add_phones = this.add_phones.filter(el => el !== '');
      this.city.add_phones = this.city.add_phones.join(',')
      this.city.telephony_phones = this.telephony_phones.filter(el => el !== '');
      this.city.telephony_phones = this.city.telephony_phones.join(',')
      this.city.phones_hr = this.phones_hr.filter(el => el !== '');
      this.city.phones_hr = this.city.phones_hr.join(',')
      this.city.notification_emails = this.notification_emails.filter(el => el !== '');
      this.city.notification_emails = JSON.stringify(this.city.notification_emails)

      this.city.price = this.price;
      console.log('Updated city data', this.cityId, this.city)
      server.request(`cities/update/${this.cityId}`, this.city, (data) => {
        this.loading = false;
        data.success ? this.$root.notify('Город сохранен') && this.$root.back('/countries') : this.$root.notify('Ошибка сохранения: ' + data.error, 'error');
      }, () => {
        this.loading = false
      })
    },
    preparePriceFields(services) {
      let fromServer = {}
      for (let key in services.services) {
        let priceField = services.services[key].priceField;
        if (this.price_services[priceField]) {
          fromServer[priceField] = services.services[key].label
          delete (this.price_services[priceField]);
        }
      }
      for (let key in services.check_services) {
        let priceField = services.check_services[key].priceField;
        if (this.price_services[priceField]) {
          fromServer[priceField] = services.check_services[key].label
          delete (this.price_services[priceField]);
        }
      }
      for (let key in fromServer) {
        this.price_services[key] = fromServer[key];
      }
      this.$forceUpdate();
    },
    getRegions() {
      this.$store.state.server.request(`cities/getRegions/${this.cityId}`, {}, (data) => {
        this.loading_regions = false
        this.regions = data.response
      }, () => {
        this.loading_regions = false
      })
    },
    addRegion() {
      const modal = this.$refs.addRegionModal
      modal.city_id = this.cityId
      modal.open()
    },
  },
  created() {
    this.getCity();
    if (this.$store.state.user.services) this.preparePriceFields(this.$store.state.user.services)
    this.getRegions()
  },
  watch: {
    '$store.state.user.services': function (newServices) {
      console.log('$store.state.user.services.services', newServices)
      this.preparePriceFields(newServices);
    },
  }
}
</script>

<style scoped>

</style>
